// this is how velocity-ui finds the Velocity instance, so lets make sure we find the right instance
let g;

if(window) {
	g = (window['jQuery'] || window);
}

export let velocity = g && g.Velocity;

if (NewFeatures.TesterCommandsEnabled && velocity && window.location.href.indexOf('chromatic=true') > - 1) {
	velocity.mock = true;
}
